<template>
  <el-dialog
    :visible.sync="showQuestionDialog"
    width="50%"
    :close-on-click-modal="false"
    class="index-up"
    :title="!actionCropper ? 'Đặt câu hỏi' : 'Chọn vùng ảnh chứa câu hỏi'"
    custom-class="modal-add-question position-absolute m-0"
    :show-close="false"
    :before-close="handleClose"
    :destroy-on-close="true"
  >
    <img src="~/mlearn/icons/modal/close-modal.svg" alt="" class="close-modal pointer" @click="handleClose()"/>
    <!--    <div class="dialog-title">-->
    <div v-show="!actionCropper">
      <div class="label-select">Lớp</div>
      <ClassRadio :require="true" :all="false" :selected-id="formData.class_id" @findClass="findClass"/>
      <div class="label-select">Môn</div>
      <CategoryRadio :require="true" :all="false" :selected-id="formData.category_id" :junior-class="juniorClass" @findClass="findCategory"/>
      <TinyEditor v-model="formData.content" class="question-editor" placeholder="Nhập nội dung câu hỏi" :disable="!user" @handle-upload="handleUpload"/>
      <div class="d-flex list-image">
        <div v-for="(it, ind) in formData.image" :key="it" class="position-relative">
          <img :src="it" class="thumbnail"/>
          <img src="~/mlearn/icons/modal/remove-image.svg" class="remove position-absolute pointer" @click="removeImage(ind)" alt="send"/>
        </div>
      </div>
      <!--    </div>-->
      <div class="note" v-if="!actionCropper">Mỗi lần đặt 1 câu hỏi hoặc 1 bài tập, không gửi hình ảnh dạng bộ đề, nhiều câu hỏi.</div>
      <div class="note" v-else>Vui lòng chỉ chọn 1 câu hỏi cho 1 lượt hỏi để nhận được câu trả lời nhanh và chính xác nhất.</div>
      <div class="row align-items-center pt-0 pt-lg-2">
        <!--        <div class="col-lg-6 pt-2 pt-lg-0">-->
        <!--          &lt;!&ndash;          <ClassSelect class="mr-2 mr-lg-3" :require="true" :all="false" @findClass="findClass"/>&ndash;&gt;-->
        <!--          &lt;!&ndash;          <CategorySelect class="mr-0 mr-lg-5" :require="true" :all="false" @findCategory="findCategory"/>&ndash;&gt;-->
        <!--        </div>-->
        <div class="col-lg-6 pt-2 pt-lg-0 d-none">
          <input type="file" class="d-none" ref="fileInput" accept="image/*" @change="changeFile">
          <div class="d-flex" v-if="showGuru">
            <b-form-radio v-model="checkboxSendGuru" :value="true">
              Hỏi gia sư
            </b-form-radio>
            <b-form-radio class="ml-3" v-model="checkboxSendGuru" :value="false">
              Hỏi cộng đồng
            </b-form-radio>
          </div>
        </div>
      </div>
      <div class="row no-gutters align-items-center" v-if="showGuru">
        <div class="col-md-9 order-1 order-md-0">
          <div class="mt-2" v-if="user && checkboxSendGuru">Bạn còn <span class="number-qa">{{ remainQuestion }}</span> lượt hỏi gia sư</div>
          <!-- <div class="mt-2 number-qa" v-if="user">* Bạn có <span class="number">{{ remainQuestion }}</span> lượt hỏi gia sư trong tháng.</div> -->
        </div>
      </div>
      <div class="row no-gutters">
        <div class="offset-lg-6 col-lg-6 pt-1 pt-lg-0 text-right">
          <div class="d-flex justify-content-center lg-justify-content-end" style="gap:10px">
            <button class="action-send" @click="handleUpload()">Chụp ảnh <img src="~/mlearn/icons/modal/image.svg" class="send" alt="image"/></button>
            <button class="action-send disabled" v-if="disableSend">Gửi <img src="~/mlearn/icons/modal/question-send.svg" class="send" alt="send"/></button>
            <button v-else @click="checkBeforeSend" class="action-send">Gửi <img src="~/mlearn/icons/modal/question-send.svg" class="send" alt="send"/></button>
          </div>
        </div>
<!--        <div class="mt-2 download-app d-none">(Tải App mLearn và mời bạn bè để nhận thêm lượt hỏi bài gia sư miễn phí)</div>-->
      </div>
    </div>
    <div v-show="actionCropper" id="form-crop-image">
      <VueCropper ref="cropper" :src="fileCropper || imagePreview" :mode="2" :auto-crop-area="1" :img-style="{maxHeight:'400px'}"/>
      <div class="row pt-3 align-items-center">
        <div class="col-lg-6 offset-lg-3 text-center">
          <img src="~/mlearn/icons/modal/rotate.svg" @click="rotate(90)"/>
        </div>
        <div class="col-lg-3 text-right">
          <button class="action-send" @click="cropImage()">Chọn</button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {mapState} from 'vuex'
import axios from 'axios'
import VueCropper from 'vue-cropperjs'
import Resource from '~/common/api/resource'
import TinyEditor from '~/components/mlearn/extra/Editor'
// import CategorySelect from '~/components/mlearn/select/category.vue'
// import ClassSelect from '~/components/mlearn/select/class.vue'
import 'cropperjs/dist/cropper.css'
import {getRemainQuestion} from '~/common/api/guru'
import {getToken} from '~/common/utils/auth'
import ClassRadio from '~/components/mlearn/select/class-radio.vue'
import CategoryRadio from '~/components/mlearn/select/category-radio.vue'

const questionResource = new Resource('qa')
export default {
  components: {
    CategoryRadio,
    ClassRadio,
    TinyEditor,
    // CategorySelect,
    // ClassSelect,
    VueCropper
  },
  data () {
    return {
      formData: {
        class_id: null,
        category_id: null,
        content: '',
        question_type: 1,
        is_formula: 0,
        image: []
      },
      showGuru: true,
      checkboxSendGuru: true,
      juniorClass: false,
      content: '',
      list_img: [],
      loading: false,
      remainQuestion: 0,
      actionCropper: false,
      fileCropper: '',
      imagePreview: '',
      imageUpload: ''
    }
  },
  computed: {
    ...mapState([
      'isVerifiedMobile',
      'isMobile',
      'user',
      'showQuestionDialog'
    ]),
    disableSend () {
      if (this.formData.class_id && this.formData.category_id && this.formData.content) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    showQuestionDialog () {
      this.formData = {
        class_id: null,
        category_id: null,
        content: '',
        question_type: 1,
        is_formula: 0,
        image: []
      }
      this.showGuru = false
      this.actionCropper = false
      this.imagePreview = ''
      this.fileCropper = ''
      this.imageUpload = ''
      if (this.showQuestionDialog) {
        this.getRemainQuestion()
      }
    }
  },
  mounted () {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'modal-guru-verify') {
        const questionType = this.checkboxSendGuru ? 2 : 1
        this.sendQuestionPublic(questionType)
      }
    })
  },
  methods: {
    handleClose () {
      this.$store.dispatch('setShowQuestionDialog', false)
    },
    handleUpload () {
      if (this.user) {
        this.$refs.fileInput.click()
      } else {
        this.$router.push('/user/login')
      }
    },
    findClass (classId, item, juniorClass) {
      this.formData.class_id = classId
      this.showGuru = false
      this.juniorClass = juniorClass ?? false
      if (this.formData.class_id && item.send_guru === 1) {
        this.showGuru = true
        this.checkboxSendGuru = false
      }
    },
    findCategory (categoryId) {
      this.formData.category_id = categoryId
    },
    checkBeforeSend () {
      if (this.loading) {
        return
      }
      if (!this.user) {
        this.$router.push('/user/login')
        return
      }
      // if (!this.isVerifiedMobile) {
      //   this.$bvModal.show('modal-guru-verify')
      //   return
      // }
      const questionType = this.checkboxSendGuru ? 2 : 1
      this.sendQuestionPublic(questionType)
    },
    sendQuestionPublic (questionType = 1) {
      this.formData.content = this.formData.content.trim()
      if (!this.formData.content) {
        return
      }
      if (!this.formData.class_id) {
        return
      }
      if (!this.formData.category_id) {
        return
      }
      this.formData.question_type = questionType
      this.loading = true
      let isFormula = 0
      const match = this.content.match(/<math/gi)
      if (match) {
        isFormula = 1
      }
      this.formData.is_formula = isFormula

      questionResource.store(this.formData)
        .then((response) => {
          const data = response.data
          this.formData.content = ''
          if (data) {
            this.$store.dispatch('setShowQuestionDialog', false)
            this.$notify({
              title: 'Thành công',
              message: 'Gửi câu hỏi thành công.',
              type: 'success'
            })
            if (this.$route.name !== 'index') {
              if (this.isVerifiedMobile) {
                this.$router.push({
                  path: '/cau-hoi/' + data.id
                })
              } else {
                this.$router.push('/')
              }
            }
          } else {
            this.$notify.error({
              title: 'Có lỗi',
              message: 'Có lỗi xảy ra vui lòng thử lại sau!'
            })
          }
        })
        .catch((err) => {
          if (err.response.data.status === 405) {
            this.$store.dispatch('setShowQuestionDialog', false)
            this.$bvModal.show('modal-redirect-sub')
          } else {
            this.$notify.error({
              title: 'Có lỗi xảy ra',
              message: err.response.data.message
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getRemainQuestion () {
      if (this.user) {
        const {data} = await getRemainQuestion()
        // this.remainQuestion = data?.text || ''
        this.remainQuestion = data?.remain_question || 0
      }
    },
    changeFile () {
      const files = this.$refs.fileInput.files
      const x = 0
      const file = files[x]
      const size = file.size
      if (size === 0) {
        this.loading = false
        this.$notify({
          title: 'Lỗi',
          message: 'Ảnh lỗi',
          type: 'error'
        })
        this.$refs.fileInput.value = ''
        return
      }
      if (size > this.sizeLimit * 1024 * 1024) {
        this.$notify({
          title: 'Lỗi',
          message: `Dung lượng không vượt quá ${this.sizeLimit}MB`,
          type: 'error'
        })
        this.$refs.fileInput.value = ''
        return
      }
      this.loading = true
      const reader = new FileReader()
      reader.onload = (e) => {
        this.fileCropper = e.target.result
        this.actionCropper = true
        this.$refs.cropper.replace(e.target.result)
      }
      reader.readAsDataURL(file)
      this.$refs.fileInput.value = ''
    },
    cropImage () {
      const copper = this.$refs.cropper.getCroppedCanvas()
      const image = copper.toDataURL()
      copper.toBlob((blob) => {
        this.actionCropper = false
        this.imagePreview = image
        this.imageUpload = blob
        this.uploadImg()
      }, 'image/png')
    },
    rotate (deg) {
      this.$refs.cropper.rotate(deg)
    },
    uploadImg () {
      if (!this.imageUpload) {
        return
      }
      const token = getToken()
      const formData = new FormData()
      formData.append('file', this.imageUpload)
      const config = {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
      axios.post(process.env.NUXT_ENV_BASE_API_UPLOAD + '/upload/qa/image', formData, config)
        .then((response) => {
          if (response.status === 200) {
            this.formData.image = response.data.data.map(function (data) {
              return data.fileDownloadUri
            })
          }
        })
        .catch((err) => {
          this.$notify({
            title: 'Lỗi',
            message: err.response.data.message,
            type: 'error'
          })
          this.$refs.fileInput.value = ''
        })
        .finally(() => {
          this.loading = false
        })
    },
    removeImage (ind) {
      if (this.formData.image[ind]) {
        this.formData.image.splice(ind, 1)
      }
    }
  }
}
</script>

<style lang="scss">
.modal-add-question {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--white);
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.13);
  border-radius: 12px;
  width: 700px !important;
  max-width: 95%;
  position: relative;

  .close-modal {
    position: absolute;
    top: 14px;
    right: 14px;
  }

  &.el-dialog {
    border-radius: 20px;
  }

  .el-dialog__header {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 22px;
    border-bottom: 1px solid #D9D9D9;
    font-family: 'SFProDisplay';
    font-style: normal;
    padding: 5px 0 10px;
    @media (max-width: 1366px) {
      font-size: 20px;
      padding: 10px;
    }
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  .el-dialog__body {
    padding: 0;
    color: initial;
    padding: 5px 25px 25px;
    @media (max-width: 1366px) {
      padding: 5px 20px 16px;
    }
    @media (max-width: 576px) {
      padding: 5px 16px 16px;
    }

    .label-select {
      font-family: 'SFProDisplay';
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #35424B;
      margin-bottom: 10px;
    }

    .note {
      font-weight: 400;
      font-size: 14px;
      color: #65676B;
      margin-bottom: 20px;
      @media (max-width: 1366px) {
        margin-bottom: 12px;
      }
      @media (max-width: 576px) {
        margin-bottom: 10px;
      }
    }

    .question-editor {
      background: var(--white);
      border: 1px solid #EBEBEB;
      border-radius: 8px;
      margin-bottom: 12px;
      @media (max-width: 1366px) {
        margin-bottom: 12px;
      }
      @media (max-width: 576px) {
        margin-bottom: 10px;
      }

      .tox-tinymce {
        .tox-editor-container {
          border-radius: 8px;
        }

        .tox-editor-header {
          .tox-toolbar__primary {
            border-top: 1px solid #EBEBEB !important;
          }
        }
      }
    }

    .number-qa {
      font-weight: 500;
      font-size: 14px;
      color: var(--danger);
    }

    .download-app {
      font-size: 12px;
      color: #65676B;
    }

    .action-send {
      float: right;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 8px 10px;
      width: 140px;
      background: #004390;
      border-radius: 22px;
      font-size: 16px;
      font-weight: bold;
      color: var(--white);
      @media (max-width: 1366px) {
        padding: 8px 10px;
      }
      @media (max-width: 576px) {
        padding: 8px 10px;
        width: 140px;
      }

      &.disabled {
        background: #868789;
        font-weight: 500;

        &:hover {
          box-shadow: none;
        }
      }

      &:hover {
        box-shadow: 0px 0px 8px rgba(30, 120, 255, 0.5);
      }
    }

    .list-image {
      > div {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }

        .thumbnail {
          width: 100px;
          height: 100px;
          object-fit: cover;
          @media (max-width: 1366px) {
            width: 90px;
            height: 90px;
          }
          @media (max-width: 576px) {
            width: 80px;
            height: 80px;
          }
        }

        .remove {
          top: 5px;
          right: 5px;
        }
      }
    }
  }
}
</style>
