<template>
  <b-modal id="modal-report" hide-footer centered header-class="pb-0 border-0" body-class="pt-0 px-2" footer-class="border-0">
    <div class="report">
      <div class="content">
        <h4 class="title">Báo vi phạm</h4>
        <span class="description">Hãy nêu rõ lí do báo vi phạm của nội dung này</span>
        <div v-for="(value,index) in listReport" :key="index">
          <b-form-radio class="report-item" v-model="selected" :name="value.content" :value="value">{{value.content}}</b-form-radio>
        </div>
        <b-form-textarea v-if="selected.content === 'Khác'" class="another-report mb-2 resize-none" v-model="report" id="textarea-default" size="sm" placeholder="Vui lòng nhập ý kiến của bạn" ></b-form-textarea>
        <div class="d-flex justify-content-end pt-2">
          <b-button pill variant="outline-secondary" @click="$bvModal.hide('modal-report')">Hủy</b-button>
          <b-button pill variant="primary" @click="sendReport">Gửi</b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

import Resource from '~/common/api/resource'
const claimListReportApi = new Resource('claim/define')
const createClaimApi = new Resource('claim')
export default {
  props: {
    level: {
      idQA: Object,
      required: true
    }
  },
  data () {
    return {
      listReport: [],
      selected: '',
      report: '',
      type: 1
    }
  },
  created () {
    this.getListReport()
  },
  methods: {
    async sendReport () {
      this.report = this.report.trim()
      if (!this.selected) {
        return
      }
      let setFlag
      let dataQuery
      if (this.level.level === 'QUESTION') {
        this.type = '100'
        setFlag = {
          setFlagQuestion: true,
          setFlagAnswer: ''
        }
        if (this.selected.content === 'Khác') {
          if (!this.report) {
            this.$notify.success({
              message: 'Chưa nhập nội dung báo cáo !',
              showClose: true,
              position: 'top-right'
            })
            return
          }
          if (this.report.length > 400) {
            this.$notify.success({
              message: 'Nội dung báo cáo không thể dài quá 400 ký tự !',
              showClose: true,
              position: 'top-right'
            })
            return
          }
          dataQuery = {
            type: this.type,
            objectUuid: this.level.idQA,
            claimDefineUuid: this.selected.uuid,
            claimContent: this.report
          }
        } else {
          dataQuery = {
            type: this.type,
            objectUuid: this.level.idQA,
            claimDefineUuid: this.selected.uuid,
            claimContent: this.selected.content
          }
        }
      } else if (this.level.level === 'ANSWER') {
        this.type = '101'
        setFlag = {
          setFlagQuestion: '',
          setFlagAnswer: this.level.idQA
        }
        if (this.selected.content === 'Khác') {
          if (!this.report) {
            this.$notify.success({
              message: 'Chưa nhập nội dung báo cáo !',
              showClose: true,
              position: 'top-right'
            })
            return
          }
          if (this.report.length > 400) {
            this.$notify.success({
              message: 'Nội dung báo cáo không thể dài quá 400 ký tự !',
              showClose: true,
              position: 'top-right'
            })
            return
          }
          dataQuery = {
            type: this.type,
            objectUuid: this.level.idQA,
            claimDefineUuid: this.selected.uuid,
            claimContent: this.report
          }
        } else {
          dataQuery = {
            type: this.type,
            objectUuid: this.level.idQA,
            claimDefineUuid: this.selected.uuid,
            claimContent: this.selected.content
          }
        }
      }
      const result = await createClaimApi.store(dataQuery)

      if (result.status === 200) {
        this.$notify.success({
          message: 'Báo cáo vi phạm thành công !',
          showClose: true,
          position: 'top-right'
        })
        this.$bvModal.hide('modal-report')
        this.$emit('statusFlagQuestion', setFlag)
      } else if (result.status === 201) {
        this.$notify.success({
          message: 'Bạn đã báo cáo vi phạm nội dung này rồi nhé !',
          showClose: true,
          position: 'top-right'
        })
        this.$bvModal.hide('modal-report')
        this.$emit('statusFlagAnswer', setFlag)
      }
    },

    async getListReport () {
      this.listReport = []
      const result = await claimListReportApi.list().catch(() => {
        this.$notify.error({
          message: 'Hệ thống đang bận bạn vui lòng thử lại sau',
          showClose: true,
          position: 'top-right'
        })
      })
      if (result && result.status === 200) {
        const list = result.data
        for (let i = 0; i < result.data.length; i++) {
          if (list[i].level === this.level.level) {
            this.listReport.push(list[i])
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" >
#modal-report{
  &___BV_modal_outer_{
    z-index: 9999 !important;
  }
  .report .btn{
    width: 100px;
    height: 40px;
    margin: 0px 5px;
  }
}
</style>

<style lang="scss" scoped>
  .content{
    padding: 0px 20px 0 35px;
  }
  .description{
    color: #92a2bf;
    font-size: 14px;
    font-weight: normal;
    padding-bottom: 5px;
  }
  .title{
    font-size: 22px;
    font-weight: bold;
  }
  .report-item{
    font-size: 16px;
    padding: 5px 0px;
  }
</style>
