<template>
  <div>
    <b-modal id="modal-suggest-sub" v-model="showModal" hide-header hide-footer centered no-close-on-backdrop no-close-on-esc body-class="position-relative">
      <img alt="logo" src="~/mlearn/icons/modal/close-modal.svg" class="close pointer position-absolute" @click="showModal = false">
      <div class="dialog-title">
        BẠN MUỐN ĐẶT CÂU HỎI
      </div>
      <div class="action text-center">
        <div class="package" v-for="sub in listSub" :key="sub.id">
          <div class="title pt-2">Soạn <span class="blue-text">DK ML</span> gửi <span class="blue-text">9285</span> để đặt câu hỏi <b class="d-inline-block">MIỄN PHÍ</b> <span class="d-inline-block">với gia sư ngay hôm nay.</span></div>
          <p><i>(Sau KM phí DV 3.000đ/ngày)</i></p>
          <div class="redirect-btn" @click="showMessage(sub)" v-if="isMobile">Đăng ký</div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Resource from '~/common/api/resource'

const subResource = new Resource('price/sub')
export default {
  data () {
    return {
      showModal: false,
      listSub: [],
      message: ''
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  created () {
    this.loadSub()
  },
  methods: {
    // redirectSub () {
    //   this.$router.push('/goi-cuoc?sub=1')
    //   this.$bvModal.hide('modal-suggest-sub')
    // }
    showMessage (sub) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (this.isMobile) {
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          window.open(`sms:${sub.service_number}&body=${sub.reg_syntax}`, '_blank')
        } else {
          window.open(`sms:${sub.service_number}?body=${sub.reg_syntax}`, '_blank')
        }
      }
    },
    async loadSub () {
      const {data} = await subResource.list()
      this.listSub = data
    }
  }
}
</script>

<style lang="scss">
#modal-suggest-sub {
  &___BV_modal_outer_ {
    z-index: 999 !important;

    .modal-dialog {
      max-width: 500px;
      margin: 0 auto;
      @media (max-width: 576px) {
        width: 90%;
      }

      .modal-content {
        border-radius: 20px;
      }

      .modal-body {
        padding: 15px 10px;

        .close {
          right: 10px;
          top: 10px;
          @media (max-width: 576px) {
            width: 15px;
            height: 15px;
            right: 10px;
            top: 10px;
          }
        }

        .dialog-title {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 20px;
          text-align: center;
          font-family: 'Inter';
          @media (max-width: 576px) {
            font-size: 18px;
          }
        }

        .title {
          font-size: 17px;
          text-align: center;
          margin-bottom: 10px;
          @media (max-width: 576px) {
            font-size: 16px;
          }
        }

        .action {
          margin-top: 15px;

          .blue-text {
            color: #0374FC;
            font-weight: bold;
          }

          p {
            font-size: 14px;
          }
        }

        .package {
          &:last-of-type {
            display: none;
          }

          &_name {
            font-weight: bold;
            font-size: 17px;
          }

          span {
            display: inline-block;
          }

          &:last-of-type {
            border-left: 1px solid #747474;
          }

          p {
            margin-bottom: 10px;
            @media (max-width: 576px) {
              margin-bottom: 10px;
            }
          }
        }

        .redirect-btn {
          cursor: pointer;
          font-size: 16px;
          text-align: center;
          color: var(--white);
          background: #0374FC;
          border-radius: 20px;
          padding: 3px 16px;
          text-transform: uppercase;
          margin: 0 auto;
          width: 150px;
        }
      }
    }
  }
}

</style>
