<template>
  <b-modal id="modal-login-now" v-model="showModal" hide-header hide-footer centered no-close-on-backdrop no-close-on-esc dialog-class="m-auto">
    <img alt="close" src="~/mlearn/icons/modal/close-modal.svg" class="close-modal pointer position-absolute" @click="showModal = false">
    <div class="title">Bạn cần đăng nhập để sử dụng tính năng này!</div>
    <div class="action">
      <button @click="loginNow">Đăng nhập ngay</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  data () {
    return {
      showModal: false,
      listClass: [],
      profile: null,
      classId: ''
    }
  },
  methods: {
    loginNow () {
      this.$router.push('/user/login')
      this.$bvModal.hide('modal-login-now')
    }
  }
}
</script>

<style lang="scss">
#modal-login-now{
  .modal-dialog{
    width:515px;
    max-width: 100%;
    .modal-body{
      padding:25px;
      @media (max-width:576px) {
        padding:20px !important;
      }
      .close-modal{
        right: 16px;
        top: 12px;
      }
      .title{
        font-weight: 500;
        font-size: 25px;
        text-align: center;
        color: #333333;
      }
      .description{
        font-weight: 400;
        font-size: 15px;
        text-align: center;
        color: #696969;
      }
      .list-class{
        > * {
          margin-top: 20px;
          .class-item{
            background: var(--color);
            border: 1px solid var(--color);
            border-radius: 30px;
            padding:8px 0;
            max-width:100%;
            margin: 0 10px;
            --color: #F8F8F8F8;
            color: #404040;
            @media (max-width:576px) {
              margin: 0 5px;
            }
            &.active{
              --color:var(--warning);
              color: #FFFFFF;
            }
          }
        }
      }
      .action{
        margin-top: 25px;
        text-align: center;
        button{
          background:#004390;
          border-radius: 26px;
          padding:10px 0;
          font-weight: 500;
          font-size: 18px;
          text-align: center;
          color: #FFFFFF;
          width: 220px;
          max-width: 100%;
          border:none;
          &.none{
            background: #C4C4C4;
          }
        }
      }
    }
  }
}
</style>
