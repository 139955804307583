<template>
  <b-modal id="modal-guru-verify" hide-footer @shown="hideFormEnterOtp" no-close-on-esc no-close-on-backdrop centered>
    <div class="content-verify" v-if="!showEnterOtp">
      <div v-if="!userMobile">
        <h4>Nhập số điện thoại để xác thực</h4>
        <p class="mb-text">Số điện thoại</p>
        <b-input-group prepend="+84" class="mb-2 mr-sm-2 mb-sm-0 mb-input">
          <b-input v-model="mobile" required ref="input-phone" placeholder="Nhập số điện thoại"></b-input><br>
        </b-input-group>
        <span class="text-danger">{{ textError }}</span>
        <p class="mb-desc">Vui lòng xác thực tài khoản qua số điện thoại để bắt đầu sử dụng dịch vụ hỏi bài gia sư.</p>
        <p class="mb-info">(Mã xác thực sẽ được gửi miễn phí qua cuộc gọi)</p>
      </div>
      <div v-else>
        <h4>Xác thực số điện thoại</h4>
        <p class="mb-text">Số điện thoại</p>
        <div class="fake-input d-flex">
          <span class="code border-0 text-white text-center">+84</span>
          <span class="input">{{ userMobile }}</span>
        </div>
        <p class="mb-desc">Vui lòng xác thực tài khoản qua số điện thoại để bắt đầu sử dụng dịch vụ hỏi bài gia sư.</p>
        <p class="mb-info">(Mã xác thực sẽ được gửi miễn phí qua cuộc gọi)</p>
      </div>
      <div class="d-flex justify-content-end bd-highlight">
        <b-button class="btn-skip" @click="$bvModal.hide('modal-guru-verify')">Bỏ qua</b-button>
        <b-button class="btn-send" @click="handleSendVerify">Gửi mã xác thực</b-button>
      </div>
    </div>
    <div class="content-otp" v-if="showEnterOtp">
      <h4>Xác thực tài khoản</h4>
      <p class="verify-text">Nhập mã xác thực vừa gửi đến số điện thoại của bạn</p>
      <p class="verify-label">Mã xác thực</p>
      <b-form-input class="verify-code shadow-none" v-model="otpCode" required ref="input-otp" placeholder="Nhập mã xác thực"></b-form-input>
      <span class="text-danger">{{ textOtpError }}</span>
      <div class="verify-bottom d-flex">
        <p class="verify-desc">Không nhận được mã? <span class="verify-countdown">({{ time }}s)</span></p>
        <p class="re-send" v-if="time===0" @click="resendVerify">Gửi lại mã</p>
      </div>
      <div class="d-flex justify-content-end bd-highlight">
        <b-button class="btn-send" @click="verifyOtp">Hoàn thành</b-button>
      </div>
    </div>
  </b-modal>
</template>
<script type="text/javascript">
import Cookies from 'js-cookie'
import { sendVerifyMobile, verifyOtp, resendVerify } from '~/common/api/verify'
import { removeVerify } from '~/common/utils/auth'

export default {
  data () {
    return {
      mobile: '',
      otpCode: '',
      showEnterOtp: false,
      textError: '',
      textOtpError: '',
      interval: null,
      time: 90
    }
  },
  computed: {
    userMobile () {
      if (this.$store.state.user && this.$store.state.user.mobile) {
        return this.$store.state.user.mobile.substring(1)
      }
      return ''
    }
  },
  watch: {
    time (val) {
      if (val === 0) {
        clearInterval(this.interval)
      }
    }
  },
  methods: {
    showFormEnterOtp () {
      this.time = 90
      this.interval = setInterval(() => {
        this.time--
      }, 1000)
      this.showEnterOtp = true
      return this.showEnterOtp
    },
    hideFormEnterOtp () {
      this.showEnterOtp = false
      this.otpCode = ''
      if (this.interval) {
        clearInterval(this.interval)
      }
      this.time = 90
    },
    handleSendVerify () {
      let newMobile = this.mobile
      if (newMobile && newMobile.startsWith('0')) {
        newMobile = newMobile.substring(1)
      }
      const mobile = this.userMobile ? '0' + this.userMobile : '0' + newMobile
      if (!this.$helper.validPhone(mobile)) {
        this.textError = 'Số điện thoại không hợp lệ'
        return false
      }
      sendVerifyMobile({
        mobile
      })
        .then((response) => {
          if (response.status === 200) {
            this.showFormEnterOtp()
          }
        })
        .catch((err) => {
          return this.$notify({
            title: 'Lỗi',
            message: err.response.data.message.replace(mobile, '+84' + mobile.substring(1)),
            type: 'error'
          })
        })
    },
    resendVerify () {
      resendVerify()
        .then((response) => {
          if (response.status === 200) {
            this.otpCode = ''
            this.showFormEnterOtp()
          }
        })
        .catch((err) => {
          return this.$notify({
            title: 'Lỗi',
            message: err.response.data.message,
            type: 'error'
          })
        })
    },
    verifyOtp () {
      if (!this.otpCode) {
        this.textOtpError = 'Vui lòng nhập mã xác thực.'
        this.$refs['input-otp'].$el.classList.add('is-invalid')
        return false
      }
      const data = {
        otp: this.otpCode
      }
      verifyOtp(data)
        .then((response) => {
          if (response.status === 200) {
            removeVerify()
            this.$store.dispatch('setVerifiedMobile', 1)
            this.$bvModal.hide('modal-guru-verify')
            return this.$notify({
              title: 'Thành công',
              message: 'Xác thực số điện thoại thành công.',
              type: 'success'
            })
          }
        })
        .catch((err) => {
          return this.$notify({
            title: 'Lỗi',
            message: err.response.data.message,
            type: 'error'
          })
        })
    },
    hiddenVerify () {
      Cookies.set('hiddenVerify', true, {
        expires: 1
      })
    }
  }
}
</script>
<style lang="scss">
  #modal-guru-verify {
    &___BV_modal_outer_ {
      z-index: 9999 !important;
    }
    .modal-dialog {
      margin-top: 75px;
    }
    .modal-header {
      border-bottom: initial;
      padding-bottom: 0;
    }
    .modal-body {
      padding-top: 0;
    }
  }
</style>
<style lang="scss" scoped>
  #modal-guru-verify {
    input {
      height: 40px;
    }
    h4 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 12px;
      @media (max-width:1366px) {
        font-size: 20px;
      }
    }
    .mb-text {
      font-size: 16px;
      margin-bottom: 10px;
      @media (max-width:1366px) {
        font-size: 15px;
      }
    }
    .mb-input {
      input {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 1px solid #e1e7f5 !important;
        border-left: none;
      }
      .input-group-text {
        background: #004390;
        border: none;
        color: var(--white);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 0.375rem 0.95rem;
        @media (max-width:1366px) {
          padding: 0.25rem 0.75rem;
        }
      }
    }
    .mb-desc {
      font-size: 14px;
      margin-top: 12px;
      margin-bottom: 0;
    }
    header button {
      font-size: 16px;
      @media (max-width:1366px) {
        font-size: 15px;
      }
    }
  }
  .group-form {
    margin-bottom: 10px;
  }
  .btn-skip{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    background: #eef1f2;
    border-color: #eef1f2;
    color: unset;
    padding: 10px 18px;
    border-radius: 26px;
  }
  .btn-send {
    margin-top: 10px;
    margin-bottom: 10px;
    background: #004390;
    border-color: #004390;
    padding: 10px 18px;
    border-radius: 26px;
  }
  .verify-text {
    font-size: 14px;
    color: #92a2bf;
    margin-bottom: 10px;
  }
  .verify-label {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .verify-code {
    border-radius: 8px;
    border: solid 1px #dce9f3 !important;
    margin-bottom: 5px;
  }
  .verify-bottom {
    display: flex;
    .verify-desc {
      font-size: 16px;
      color: #172948;
      margin-bottom: 0;
      margin-top: 15px;
      .verify-countdown {
        color: #004390;
      }
    }
    .re-send {
      font-size: 16px;
      color: #004390;
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 0;
      margin-left: auto;
    }
  }
  .fake-input {
    .code {
      background: #004390;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      font-size: 1rem;
      height: 40px;
      width: 50px;
      line-height: 40px;
    }
    .input {
      height: 40px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: 1px solid #e1e7f5!important;
      border-left: none;
      width: 100%;
      padding: 0 .75rem;
      line-height: 40px;
    }
  }
  .mb-info {
    margin-top: 5px;
    font-size: 12px;
  }
</style>
